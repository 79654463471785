<template>
  <v-container>
    <div class="ml-2">
      <PageTitle text="Lube Order Request" />
    </div>
    <div class="buttonText ml-1" id="result">
      <div class="d-flex flex-row flex-grow-1">
        <v-btn
          v-ripple="{ class: 'primary--text' }"
          color="#04a9f3"
          rounded
          fab
          icon
          small
          class="ml-2 mt-3 white--text"
          style="font-size: 12px"
          @click="$router.go(-1)"
        >
          <i class="material-icons-outlined primary--text">arrow_back</i>
        </v-btn>
        <div class="black--text mt-5 ml-3 absolute">Go Back</div>
      </div>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <v-card flat class="pt-5 pb-4" style="border-radius: 8px;">
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form
                @submit.prevent="handleSubmit(acknowledgeRequest)"
                class="px-3"
              >
                <v-col>
                  <v-row>
                    <div class="d-flex flex-row flex-grow-1">
                      <div class="flex-column flex-grow-1 d-flex ma-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            readonly
                            outlined
                            autofocus
                            v-model="orderReference"
                            label="Order Reference "
                            :error-messages="errors[0]"
                            hint="Order Reference "
                          />
                        </ValidationProvider>
                      </div>
                      <div class="flex-column flex-grow-1 d-flex ma-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            autofocus
                            v-model="orderNumber"
                            label="Order Number"
                            :error-messages="errors[0]"
                            hint="Order Number "
                          />
                        </ValidationProvider>
                      </div>
                      <div class="flex-column flex-grow-1 d-flex ma-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            autofocus
                            v-model="deliveryNumber"
                            label="Delivery Number"
                            :error-messages="errors[0]"
                            hint="Delivery Number "
                          />
                        </ValidationProvider>
                      </div>
                      <div class="flex-column flex-grow-1 d-flex ma-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            autofocus
                            v-model="tripNumber"
                            label="Trip Number"
                            :error-messages="errors[0]"
                            hint="Trip Number "
                          />
                        </ValidationProvider>
                      </div>
                    </div>

                    <div class="d-flex flex-row flex-grow-1 ma-1">
                      <div class="d-flex flex-column flex-grow-1 ma-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row flex-grow-1"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            autofocus
                            v-model="vehicleRegistrationNumber"
                            label="Vehicle Registration Number"
                            :error-messages="errors[0]"
                            hint="Vehicle Registration Number"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="d-flex flex-column flex-grow-1 ma-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row flex-grow-1"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            autofocus
                            v-model="invoiceNumber"
                            label="Invoice Number"
                            :error-messages="errors[0]"
                            hint="Invoice Number"
                          />
                        </ValidationProvider>
                      </div>
                      <div class="d-flex flex-column flex-grow-1 ma-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row flex-grow-1"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            autofocus
                            v-model="netAmount"
                            label="Net Amount"
                            :error-messages="errors[0]"
                            hint="Net Amount"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                  </v-row>
                  <v-row>
                    <div class="d-flex flex-column flex-fill">
                      <div
                        class="d-flex flex-column flex-fill pa-4 ma-1 "
                        style="background-color: #eeeeee; border-radius: 6px;"
                      >
                        <v-card
                          v-for="(row, index) in orderLineItems"
                          :key="index"
                          class="elevation-0 d-flex flex-column flex-fill mt-2 pt-6 mb-2 px-2"
                          style="width:100%;"
                        >
                          <div class="d-flex flex-row flex-grow-1">
                            <ValidationProvider
                              tag="div"
                              class="d-flex flex-row flex-grow-1 ma-2"
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <v-autocomplete
                                :items="products"
                                v-model="row.productId"
                                item-text="name"
                                item-value="id"
                                prepend-inner-icon="category"
                                :error-messages="errors[0]"
                                outlined
                                label="Choose A Product"
                                hint="Select from a list of product "
                                class="ft font-weight-medium text-sm"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              tag="div"
                              rules="required|numeric"
                              v-slot="{ errors }"
                              class="d-flex flex-row flex-grow-1 ma-2"
                            >
                              <v-text-field
                                outlined
                                v-model="row.quantityLoaded"
                                prepend-inner-icon="all_inclusive"
                                label="Enter Quantity Loaded"
                                readonly
                                :error-messages="errors[0]"
                                class="ft font-weight-medium text-sm"
                              />
                            </ValidationProvider>

                            <ValidationProvider
                              tag="div"
                              class="d-flex flex-row flex-grow-1 ma-2"
                              rules="required|numeric"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                outlined
                                v-model="row.quantityReceived"
                                prepend-inner-icon="all_inclusive"
                                label="Enter Quantity Delivered"
                                :error="isFormInvalid[index]"
                                :error-messages="errors[0]"
                                class="ft font-weight-medium text-sm"
                              />
                            </ValidationProvider>
                          </div>
                        </v-card>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="mt-3">
                    <div class="d-flex flex-row flex-grow-1">
                      <div class="flex-column flex-grow-1 d-flex ma-1">
                        <ValidationProvider tag="div" class="d-flex flex-row">
                          <v-text-field
                            outlined
                            autofocus
                            v-model="driverId"
                            label="Driver ID "
                            hint="Driver ID Cart #"
                          />
                        </ValidationProvider>
                      </div>
                    </div>

                    <div class="d-flex flex-row flex-grow-1 ma-1">
                      <div class="d-flex flex-column flex-grow-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row flex-grow-1"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            autofocus
                            v-model="driverName"
                            label="Driver's Name"
                            :error-messages="errors[0]"
                            hint="Driver's Full Name"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="d-flex flex-row flex-grow-1 ma-1">
                      <div class="d-flex flex-column flex-grow-1">
                        <ValidationProvider
                          tag="div"
                          class="d-flex flex-row flex-grow-1"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            autofocus
                            v-model="customerName"
                            label="Customer's Name"
                            :error-messages="errors[0]"
                            hint="Customer's Full Name"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                  </v-row>
                  <v-row class="mt-5 mr-1">
                    <div
                      class="d-flex flex-row flex-grow-1 flex-fill justify-end"
                    >
                      <v-btn
                        color="primary"
                        :disabled="loading || isOrderDelivered || isFormInvalid"
                        :loading="loading"
                        type="submit"
                        >Acknowledge Delivery</v-btn
                      >
                    </div>
                  </v-row>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>
<script>
import PageTitle from "../../components/PageTitle";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { numeric, required } from "vee-validate/dist/rules";
import { Api } from "../../api/Api";

extend("required", {
  ...required,
  message: "Field is required"
});
extend("numeric", {
  ...numeric,
  message: "Field accept only numbers"
});
export default {
  name: "LubeOrderRequest",
  components: {
    PageTitle,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      isFormInvalid: false,
      products: [],
      orderNumber: "",
      isOrderDelivered: false,
      orderReference: "",
      tripNumber: "",
      deliveryNumber: "",
      netAmount: null,
      invoiceNumber: null,
      referenceNumber: "",
      vehicleRegistrationNumber: "",
      driverName: "",
      driverId: "",
      customerName: "",
      carrierSignature: "",
      showSnackBar: false,
      message: "",
      status: "",
      loading: false,
      orderLineItems: [
        {
          productId: "",
          quantityLoaded: 0,
          uom: "",
          quantityReceived: 0
        }
      ]
    };
  },
  methods: {
    async getProducts() {
      try {
        this.loading = true;
        const response = await Api().get(
          `${process.env.VUE_APP_BASE}/products/lubes/list?paginated=false`
        );
        if (response) {
          this.loading = false;
          this.products = response.data;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.loading = false;
        this.$router.push({ name: "master.lubes" });
      }
    },
    async getRequestDetails() {
      try {
        this.loading = true;
        const response = await Api().get(
          `/orders/lubes/${this.$route.params.id}/request/details`
        );
        if (response) {
          this.loading = false;
          this.orderReference = response.data.orderReference;
          this.isOrderDelivered = response.data.isOrderDelivered;
          this.orderLineItems = response.data.lubesOrdersLineItem.map(
            orderLineItem => {
              return {
                ...orderLineItem,
                productId: orderLineItem.productId.id
              };
            }
          );
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = e.message ?? "Unable to get lubes list";
        this.status = "red";
        this.loading = false;
        this.$router.push({ name: "master.lubes" });
      }
    },
    async acknowledgeRequest() {
      try {
        this.loading = true;
        const response = await Api().put(
          `/orders/${this.$route.params.id}/lubes/mark/as/delivered`,
          {
            vehicleRegistrationNumber: this.vehicleRegistrationNumber,
            deliveryNumber: this.deliveryNumber,
            tripNumber: this.tripNumber,
            invoiceNumber: this.invoiceNumber,
            orderNumber: this.orderNumber,
            netAmount: this.netAmount,
            driverId: this.driverId,
            driverName: this.driverName,
            customerName: this.customerName,
            orderLineItem: this.orderLineItems
          }
        );
        if (response) {
          setTimeout(() => {
            this.showSnackBar = true;
            this.message = "Lube order successfully marked as delivered";
            this.status = "success";
            this.loading = false;
            this.$router.replace({
              name: "master.lubes.approved.requests"
            });
          }, 3000);
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message =
          e.message ?? "Unable to get mark lube order as delivered";
        this.status = "red";
        this.loading = false;
      }
    }
  },
  created() {
    this.getRequestDetails();
    this.getProducts();
  }
};
</script>
