var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"ml-2"},[_c('PageTitle',{attrs:{"text":"Lube Order Request"}})],1),_c('div',{staticClass:"buttonText ml-1",attrs:{"id":"result"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('v-btn',{directives:[{name:"ripple",rawName:"v-ripple",value:({ class: 'primary--text' }),expression:"{ class: 'primary--text' }"}],staticClass:"ml-2 mt-3 white--text",staticStyle:{"font-size":"12px"},attrs:{"color":"#04a9f3","rounded":"","fab":"","icon":"","small":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('i',{staticClass:"material-icons-outlined primary--text"},[_vm._v("arrow_back")])]),_c('div',{staticClass:"black--text mt-5 ml-3 absolute"},[_vm._v("Go Back")])],1)]),_c('v-row',{staticStyle:{"margin-top":"5px","margin-left":"1px","margin-right":"10px"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[[_c('v-card',{staticClass:"pt-5 pb-4",staticStyle:{"border-radius":"8px"},attrs:{"flat":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.acknowledgeRequest)}}},[_c('v-col',[_c('v-row',[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"flex-column flex-grow-1 d-flex ma-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"readonly":"","outlined":"","autofocus":"","label":"Order Reference ","error-messages":errors[0],"hint":"Order Reference "},model:{value:(_vm.orderReference),callback:function ($$v) {_vm.orderReference=$$v},expression:"orderReference"}})]}}],null,true)})],1),_c('div',{staticClass:"flex-column flex-grow-1 d-flex ma-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Order Number","error-messages":errors[0],"hint":"Order Number "},model:{value:(_vm.orderNumber),callback:function ($$v) {_vm.orderNumber=$$v},expression:"orderNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"flex-column flex-grow-1 d-flex ma-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Delivery Number","error-messages":errors[0],"hint":"Delivery Number "},model:{value:(_vm.deliveryNumber),callback:function ($$v) {_vm.deliveryNumber=$$v},expression:"deliveryNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"flex-column flex-grow-1 d-flex ma-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Trip Number","error-messages":errors[0],"hint":"Trip Number "},model:{value:(_vm.tripNumber),callback:function ($$v) {_vm.tripNumber=$$v},expression:"tripNumber"}})]}}],null,true)})],1)]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 ma-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 ma-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Vehicle Registration Number","error-messages":errors[0],"hint":"Vehicle Registration Number"},model:{value:(_vm.vehicleRegistrationNumber),callback:function ($$v) {_vm.vehicleRegistrationNumber=$$v},expression:"vehicleRegistrationNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ma-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Invoice Number","error-messages":errors[0],"hint":"Invoice Number"},model:{value:(_vm.invoiceNumber),callback:function ($$v) {_vm.invoiceNumber=$$v},expression:"invoiceNumber"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-column flex-grow-1 ma-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Net Amount","error-messages":errors[0],"hint":"Net Amount"},model:{value:(_vm.netAmount),callback:function ($$v) {_vm.netAmount=$$v},expression:"netAmount"}})]}}],null,true)})],1)])]),_c('v-row',[_c('div',{staticClass:"d-flex flex-column flex-fill"},[_c('div',{staticClass:"d-flex flex-column flex-fill pa-4 ma-1 ",staticStyle:{"background-color":"#eeeeee","border-radius":"6px"}},_vm._l((_vm.orderLineItems),function(row,index){return _c('v-card',{key:index,staticClass:"elevation-0 d-flex flex-column flex-fill mt-2 pt-6 mb-2 px-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 ma-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft font-weight-medium text-sm",attrs:{"items":_vm.products,"item-text":"name","item-value":"id","prepend-inner-icon":"category","error-messages":errors[0],"outlined":"","label":"Choose A Product","hint":"Select from a list of product "},model:{value:(row.productId),callback:function ($$v) {_vm.$set(row, "productId", $$v)},expression:"row.productId"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 ma-2",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"outlined":"","prepend-inner-icon":"all_inclusive","label":"Enter Quantity Loaded","readonly":"","error-messages":errors[0]},model:{value:(row.quantityLoaded),callback:function ($$v) {_vm.$set(row, "quantityLoaded", $$v)},expression:"row.quantityLoaded"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 ma-2",attrs:{"tag":"div","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium text-sm",attrs:{"outlined":"","prepend-inner-icon":"all_inclusive","label":"Enter Quantity Delivered","error":_vm.isFormInvalid[index],"error-messages":errors[0]},model:{value:(row.quantityReceived),callback:function ($$v) {_vm.$set(row, "quantityReceived", $$v)},expression:"row.quantityReceived"}})]}}],null,true)})],1)])}),1)])]),_c('v-row',{staticClass:"mt-3"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('div',{staticClass:"flex-column flex-grow-1 d-flex ma-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row",attrs:{"tag":"div"}},[_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Driver ID ","hint":"Driver ID Cart #"},model:{value:(_vm.driverId),callback:function ($$v) {_vm.driverId=$$v},expression:"driverId"}})],1)],1)]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 ma-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Driver's Name","error-messages":errors[0],"hint":"Driver's Full Name"},model:{value:(_vm.driverName),callback:function ($$v) {_vm.driverName=$$v},expression:"driverName"}})]}}],null,true)})],1)]),_c('div',{staticClass:"d-flex flex-row flex-grow-1 ma-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","autofocus":"","label":"Customer's Name","error-messages":errors[0],"hint":"Customer's Full Name"},model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})]}}],null,true)})],1)])]),_c('v-row',{staticClass:"mt-5 mr-1"},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-fill justify-end"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading || _vm.isOrderDelivered || _vm.isFormInvalid,"loading":_vm.loading,"type":"submit"}},[_vm._v("Acknowledge Delivery")])],1)])],1)],1)]}}])})],1)]],2)],1),_c('v-snackbar',{attrs:{"timeout":4000,"top":"","color":_vm.status},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.message))]),_c('v-btn',{attrs:{"text":"","color":"white"},on:{"click":function($event){_vm.showSnackBar = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }